import type { User } from 'lucia'
import { defineStore, acceptHMRUpdate } from 'pinia'

interface State {
    user: User | null;
    permissions: any;
}

export const useAuthStore = defineStore('auth', {
    state: (): State => ({
        user: null,
        permissions: []
    }),
    actions: {
        setUser(user: User | null) {
            this.user = user;
            if (user && user.permissions.length) {
                this.setPermissions(user.permissions)
            }
        },
        setPermissions(permissions: any) {
            this.permissions = [...this.permissions, ...permissions]
            const { $ability } = useNuxtApp()
            $ability.update(this.permissions)    
        },
        async logout() {
            await $fetch('/api/auth/logout', {
                method: 'POST'
            })
            await navigateTo('/', { external: true })
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}