<template>
    <Link href="/favicon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
    <Link href="/favicon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
    <Link href="/favicon-64x64.png" rel="icon" type="image/png" sizes="64x64" />
    <NuxtLayout name="default">
        <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
            <div class="text-center">
                <p class="font-semibold text-primary">{{ error?.statusCode }}</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl lg:text-5xl">
                    {{ error?.statusCode == 404 ? 'Page Not Found' : 'An Error Was Encountered' }}
                </h1>
                <p class="mt-2 leading-7 text-gray-600 dark:text-white">{{ error?.statusCode == 404 ? 'Sorry, we couldn\'t find what you were looking for.' : error?.message }}</p>
                <div class="mt-6">
                    <NuxtLink to="/">
                        <Button :bold="true">Go back home</Button>
                    </NuxtLink>
                </div>
            </div>
        </main>
    </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError
})

useHead({
    title: String(props.error?.statusCode)
})

const config = useState('config')

await callOnce(async () => {
    const settings = await $fetch('/api/settings')
    config.value = settings
})
</script>