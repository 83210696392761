<template>
    <Link href="/favicon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
    <Link href="/favicon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
    <Link href="/favicon-64x64.png" rel="icon" type="image/png" sizes="64x64" />
    <NuxtLoadingIndicator color="#eab308" />
    <ShadToaster position="bottom-right" offset="20px" :visibleToasts="6" expand richColors />
    <div v-if="showPreloader" id="preloader" :class="{ 'loaded': !loading }">
        <NuxtImg src="/logo-dark.png" class="h-7 block dark:hidden" />
        <NuxtImg src="/logo.png" class="h-7 hidden dark:block" />
    </div>
    <NuxtRouteAnnouncer />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()

const loading = ref(true)
const showPreloader = ref(true)

nuxtApp.hook("page:finish", () => {
    loading.value = false
    setTimeout(() => {
        showPreloader.value = false
    }, 300)
})

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - RoValue` : 'RoValue - The BIGGEST Roblox Item Values Database'
    }
})

useSeoMeta({
    description: 'Improve your trading experience with our specialized platform! View item values, create trade-ads, use trade calculators, and access a variety of tools crafted to facilitate seamless and efficient trading!',
    themeColor: '#eab308'
})

provideHeadlessUseId(() => useId())

const config = useState('config')

await callOnce(async () => {
    const settings = await $fetch('/api/settings')
    config.value = settings
})
</script>

<style scoped>
#preloader {
    @apply fixed flex w-full h-full items-center justify-center left-0 top-0 w-full h-full z-[9999] bg-white dark:bg-[#1b1d23];
}

#preloader.loaded {
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
}
</style>