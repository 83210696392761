//@ts-ignore
import type { User, Staff, DHSkin, DHCategory, DHLeak, DHAddon, HoodModdedFX, HoodModdedRarity, HoodModdedLeak, HoodModdedAddon, MM2Item, MM2Category, MM2Rarity, MM2Addon, PS99Item, PS99Addon, GPOItem, GPOCategory, GPORarity, GPOTier, GPOAddon, Trend, Demand, Ad } from '@rocketiscool/rovalue-prisma-client'
import { AbilityBuilder, PureAbility } from '@casl/ability'
import { createPrismaAbility, type PrismaQuery, type Subjects } from '@casl/prisma'
import lodash from 'lodash'

export type AppSubjects = 'all' | Subjects<{
    User: User,
    Staff: Staff,
    Ad: Ad,
    // Da Hood
    DHSkin: DHSkin,
    DHCategory: DHCategory,
    DHAddon: DHAddon,
    DHLeak: DHLeak,
    // Hood Modded
    HoodModdedFX: HoodModdedFX,
    HoodModdedRarity: HoodModdedRarity,
    HoodModdedAddon: HoodModdedAddon,
    HoodModdedLeak: HoodModdedLeak,
    // Murder Mystery 2
    MM2Item: MM2Item,
    MM2Addon: MM2Addon,
    MM2Category: MM2Category,
    MM2Rarity: MM2Rarity,
    // Pet Simulator 99
    PS99Item: PS99Item,
    PS99Addon: PS99Addon,
    Trend: Trend,
    Demand: Demand,
    // Grand Piece Online
    GPOItem: GPOItem,
    GPOCategory: GPOCategory,
    GPORarity: GPORarity,
    GPOTier: GPOTier,
    GPOAddon: GPOAddon
}>

interface Permission {
    action: string;
    subject: any;
    fields?: string | string[] | undefined;
    conditions?: any | undefined;
}

const parseJSON = (template: any, variables: any) => {
    return JSON.parse(template, (key, value) => {
        if (typeof value === 'string' && value.startsWith('${') && value.endsWith('}')) {
            const name = value.slice(2, -1)
            const variableValue = lodash.get(variables, name)
            if (typeof variableValue === 'undefined') {
                throw new ReferenceError(`Variable ${name} is not defined`);
            }
            return variableValue;
        }
        return value
    })
}

export type AppAbility = PureAbility<[string, AppSubjects], PrismaQuery>

export const defineAbilitiesFor = (user: User) => {
    const data = user.permissions || []
    const permissions = parseJSON(JSON.stringify(data), { 'user.id': user.id })
    const { can: allow, build } = new AbilityBuilder<AppAbility>(createPrismaAbility)
    permissions.forEach((item: Permission) => {
        return allow(item.action, item.subject, item.fields, item.conditions)
    })
    return build()
}