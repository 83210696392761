<template>
    <button 
        :type="type"
        v-bind="$attrs"
        :class="cn(`relative rounded-md text-sm text-white ${bold ? 'font-semibold' : 'font-medium'}`, sizeClass, variantClass, $attrs.class)"
    >
        <slot />
    </button>
</template>

<script setup>
import { cn } from '@/lib/utils'

const props = defineProps({
    type: {
        type: String,
        default: 'button'
    },
    variant: {
        type: String,
        default: 'primary',
    },
    size: {
        type: String,
        default: 'base',
    },
    bold: {
        type: Boolean,
        default: false,
    },    
})

const variantClass = computed(() => {
    return {
        'primary': 'bg-primary enabled:hover:bg-primary-hover',
        'danger': 'bg-red-600 enabled:hover:bg-red-700',
        'success': 'bg-green enabled:hover:bg-green-darker'
    }[props.variant]
})

const sizeClass = computed(() => {
    return {
        'xs': 'px-3 py-2 sm:text-xs',
        'sm': 'px-4 py-2',
        'base': 'px-4 py-2.5',
        'md': 'px-6 py-3 text-md'
    }[props.size]
})

defineOptions({
    inheritAttrs: false
})
</script>