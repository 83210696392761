import { defineAbilitiesFor } from '../../../base/services/casl'
import { abilitiesPlugin } from '@casl/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const ability = defineAbilitiesFor({
        permissions: [{}]
    })
    const can = (action: string, subject?: any, field?: string): boolean => {
        return ability.can(action, subject || 'all', field)
    }
    const cannot = (action: string, subject?: any, field?: string): boolean => {
        return ability.cannot(action, subject || 'all', field)
    }
    nuxtApp.vueApp.use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    return {
        provide: {
            ability,
            can,
            cannot
        }
    }
})