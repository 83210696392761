export default defineNuxtRouteMiddleware((to, from) => {
    const layoutMap = {
        '/da-hood': 'da-hood',
        '/hood-modded': 'hood-modded',
        '/adopt-me': 'adopt-me',
        '/bladeball': 'bladeball',
        '/mm2': 'mm2',
        '/psx': 'psx',
        '/gpo': 'gpo',
        '/ps-99': 'ps-99',
        '/anime-defenders': 'anime-defenders',
    } as const
    const prefix = Object.keys(layoutMap).find((key) => to.path.startsWith(key)) as keyof typeof layoutMap | undefined
    if (prefix && ![prefix, `${prefix}/`].includes(to.path)) {
        setPageLayout(layoutMap[prefix])
    }
})